<template>
    <canvas id="canvas" ref="canvas"></canvas>
</template>

<script>
import Module from '@/plugins/UBViewer.js'
import * as JSZip from 'jszip'
import * as JSZipUtils from 'jszip-utils'

export default {
    name: 'UbviewerEngine',
    data: () => ({
        canvas: null,
        myModule: null,
        modelId: [],
        modelType: '',
        modelJsonType: '',
        token: '',
        CanvasWidth: 0,
        CanvasHeight: 0,
        devicePixelRatio: 1,
        collisions: false,
        filters: false,
        filterList: [],
        delta: [],
        deltaJson: null,
        modelName: {},
        deltaName: {}
    }),
    methods: {
        setCanvasSize(){
            this.canvas = this.$refs['canvas']
            let el = this.$el
            let parent = this.$parent.$el
            const devicePixelRatio = window.devicePixelRatio || 1
            const widthC = parent.offsetWidth
            const heightC = parent.offsetHeight
            this.CanvasWidth = widthC
            this.CanvasHeight = heightC
            this.devicePixelRatio = devicePixelRatio

            this.canvas.width  = widthC
            this.canvas.height = heightC

            return {
                widthC,
                heightC
            }
        },
        getCanvasWidth(){
            return this.CanvasWidth
        },
        getCanvasHeight(){
            return this.CanvasHeight
        },
        getDevicePixelRatio(){
            return this.devicePixelRatio
        },
        displayAxes(v){
            this.myModule.setViewCubeVisible(v.value)
        },
        displayGrid(v){
            this.myModule.setGridVisible(v.value)
        },
        setSelectionMode(v){
            this.myModule.setSelectionMode(v.value)
        },
        selectObjectById(v){
            this.myModule.selectObjectById(v.value)
        },
        clearSelection(v){
            this.myModule.clearSelection()
        },
        onCalculateCollisions(){
            this.collisions = true
            window.parent.postMessage({
                action: 'onLoadCollisions',
                value: true
            }, "*")
        },
        loadCollisions(){
            console.log('loadCollisions run')
            return new Promise((resolve, reject) => {
                const getCollisions = () => {
                    this.$store.dispatch('getCollisions', {
                        token: this.token,
                        files: this.modelId,
                        url: ''
                    })
                    .then(res => {
                        console.log('getCollisions', res)
                        if(res.status == -1){
                            resolve(res.task_id)
                        }
                        else if(res.status == -2){
                            console.log('get collisions error: ', res.message)
                            reject({message: res.message})
                        }
                        else{
                            setTimeout(getCollisions, 5000)
                        }
                    })
                    .catch(er => {
                        console.log('get collisions error: ', er)
                        reject({message: er})
                    })
                }
                getCollisions()

            })
        },
        getCollisions(){
            // this.myModule.calculateCollisions()
            let task_id = ''
            return new Promise((resolve, reject) => {
                if(this.collisions){
                    resolve(true)
                }
                else{
                    this.loadCollisions()
                    .then(res => {
                        console.log('getCollisions result', res)
                        task_id = res
                        return this.$store.dispatch('getCollisionsJson', {
                            token: this.token,
                            files: this.modelId,
                            url: '/' + task_id + '/collisions.json',
                            typeBlob: 'blob'
                        })
                    })
                    .then(res => {
                        return res.file.arrayBuffer()
                    })
                    .then(r => {
                        return this.createFile({
                            data: new Uint8Array(r),
                            name: task_id + '_collisions.json',
                            path: '/'
                        })
                    })
                    .then(res => {
                        console.log('load collisions')
                        this.myModule.loadCollisions('/' + task_id + '_collisions.json')
                        return true
                    })
                    .then(r => {
                        console.log('load collisions end')
                        this.collisions = true
                        window.parent.postMessage({
                            action: 'onLoadCollisions',
                            value: true
                        }, "*")
                        resolve(true)
                    })
                }
            })
        },
        // getCollisions(task_id){
        //     // this.myModule.calculateCollisions()
        //     return new Promise((resolve, reject) => {
        //         let list = this.modelId.map(m => {
        //             // return this.$store.dispatch('getFile', {
        //             //     id: m + '/collisions.json',
        //             //     token: this.token,
        //             //     typeBlob: 'blob'
        //             // })
        //             this.$store.dispatch('getCollisionsJson', {
        //                 token: this.token,
        //                 files: this.modelId,
        //                 url: '/' + task_id + '/collisions.json',
        //                 typeBlob: 'blob'
        //             })
        //             .then(res => {
        //                 return res.file.arrayBuffer()
        //             })
        //             .then(r => {
        //                 return this.createFile({
        //                     data: new Uint8Array(r),
        //                     name: m + '_collisions.json',
        //                     path: '/'
        //                 })
        //             })
        //             .then(res => {
        //                 console.log('load collisions')
        //                 this.myModule.loadCollisions('/' + m + '_collisions.json')
        //                 return true
        //             })
        //         })
        //
        //         Promise.all(list)
        //         .finally(r => {
        //             console.log('load collisions end')
        //             this.collisions = true
        //             resolve(true)
        //         })
        //         .catch(e => {
        //             console.log('load collisions error', e)
        //             reject(e)
        //         })
        //     })
        // },
        setCollisions(v){
            if(v.value == true){
                if(!this.collisions){
                    this.getCollisions()
                    .then(res => {
                        this.myModule.setCollisionsVisible(true)
                    })
                }
                else{
                    this.myModule.setCollisionsVisible(true)
                }
            }
            else{
                this.myModule.setCollisionsVisible(false)
            }
        },
        setWalkView(v){
            this.myModule.setWalkView(v.value)
        },
        setView(v){
            this.myModule[v.name]()
        },
        // updateCanvasSize(){
        //     this.setCanvasSize()
        // },
        // onWindowResize(){
        //     this.updateCanvasSize()
        // },
        // onObjectSelected(Id) {
        //     console.log(Id)
        // },
        onSelected(id) {
            let a = []
            for(let i = 0; i < id.size(); i++){
                a.push(id.get(i))
            }
            window.parent.postMessage({
                action: 'onSelected',
                id: a
            }, "*")
        },
        loadFilters(){
            return new Promise(resolve => {
                //if(!this.filters){
                let no_filter = this.modelId.find(f => !this.filterList.some(s => f == s))

                if(no_filter){
                    new Promise(resolve => {
                        if(this.modelJsonType == 'json.zip'){
                            resolve(this.$store.dispatch('getFile', {
                                id: no_filter + '/json.zip',
                                token: this.token,
                                typeBlob: 'blob'
                            })
                            .then(res => {
                                return Promise.resolve(res.file)
                            })
                            .then(res => JSZip.loadAsync(res))
                            .then(zip => {
                                let filesList = Object.keys(zip.files)
                                return zip.file(filesList[0]).async("uint8array")
                            }))
                        }
                        else{
                            resolve(this.$store.dispatch('getFile', {
                                id: no_filter + '/json',
                                token: this.token,
                                typeBlob: 'blob'
                            })
                            .then(res => {
                                return res.file.arrayBuffer()
                            }))
                        }
                    })
                    .then(r => {
                        return this.createFile({
                            data: new Uint8Array(r),
                            name: no_filter + '_filters.json',
                            path: '/'
                        })
                    })
                    .then(r => {
                        this.myModule.loadInformJson('/' + no_filter + '_filters.json')
                        return true
                    })
                    .then(r => {
                        this.myModule.disableFilters()
                        return true
                    })
                    .then(r => {
                        this.filters = true
                        this.filterList = [...this.filterList, no_filter]
                        resolve(true)
                    })
                }
                else{
                    resolve(true)
                }
            })
        },
        onFilter(o){
            this.loadFilters()
            .then(r => {
                let value = o.value
                switch(value.filter){
                    case 'category':
                                    this.myModule.setCategoryFilter(value.name, value.active)
                                    break
                    case 'type':    this.myModule.setGroupFilter(
                                                        value.category,
                                                        value.name,
                                                        value.active
                                    )
                                    break
                    case 'clear':   this.myModule.clearFilters()
                                    break
                    case 'enable':  this.myModule.enableFilters()
                                    break
                    case 'disable': this.myModule.disableFilters()
                                    break
                    default: ;
                }
            })
        },
        onHideObject(a){
            let objs = new this.myModule.StringList()
            a.map(i => {
                objs.push_back(i)
            })
            this.myModule.hideObjects(objs)
            this.onGetHiddenObjects()
        },
        onShowObject(a){
            let objs = new this.myModule.StringList()
            a.map(i => {
                objs.push_back(i)
            })
            this.myModule.showObjects(objs)
            this.onGetHiddenObjects()
        },
        onRoomVisibility(d){
            if(d.value){
                this.myModule.showAllRooms()
            }
            else{
                this.myModule.hideAllRooms()
            }
        },
        onRoomVisibilityItem(d){
            let objs = new this.myModule.StringList()
            d.value.ids.map(i => {
                objs.push_back(i)
            })
            if(d.value.val){
                this.myModule.showRooms(objs)
            }
            else{
                this.myModule.hideRooms(objs)
            }
        },
        onSection(d){
            if(d.value){
                this.myModule.createSectionBox()
            }
            else{
                this.myModule.removeSectionBox()
            }
        },
        onComputeRepeat(d){
            let res = this.myModule.computeDuplicatedObjects()
            window.parent.postMessage({
                action: 'saveCollisionReports',
                value: {...JSON.parse(res), group: d.value.group, rule: d.value.rule, part: d.value.part}
            }, "*")
        },
        onComputeDistance(data){
            let params = {
                Lhs:{
                    Ids: data.value.lhs
                },
                Rhs:{
                    Ids: data.value.rhs
                },
                DistanceOperator: {
                    Method: data.value.Method,
                    SubMethod: data.value.SubMethod,
                }
            }
            if(data.value.MinDistance >= 0){
                params.DistanceOperator.MinDistance = data.value.MinDistance
            }
            if(data.value.MaxDistance >= 0){
                params.DistanceOperator.MaxDistance = data.value.MaxDistance
            }
            console.log('>>>onComputeDistance params', params)
            let res = this.myModule.checkMinMaxDistance(JSON.stringify(params))
            window.parent.postMessage({
                action: 'saveCollisionReports',
                value: {...JSON.parse(res), group: data.value.group, rule: data.value.rule, part: data.value.part}
            }, "*")
        },
        onComputeSpace(data){
            let lhs = JSON.stringify({Ids: data.value.lhs, Restrictions: data.value.axes})
            let rhs = JSON.stringify({Ids: data.value.rhs, Restrictions: data.value.axes})
            console.log('>>>onComputeSpace lhs, rhs', lhs, rhs)
            let res = this.myModule.computeSpatialCollisions(lhs, rhs)
            window.parent.postMessage({
                action: 'saveCollisionReports',
                value: {...JSON.parse(res), group: data.value.group, rule: data.value.rule, part: data.value.part}
            }, "*")
        },
        onComputeCollisions(d){
            this.getCollisions()
            .then(res => {
                let list_1 = new this.myModule.StringList()
                d.value.list_1.map(l => {
                    list_1.push_back(l)
                })
                let list_2 = new this.myModule.StringList()
                d.value.list_2.map(l => {
                    list_2.push_back(l)
                })
                let result = this.myModule.computeCollisionsByIds(list_1, list_2)
                window.parent.postMessage({
                    action: 'saveCollisionReports',
                    value: {...JSON.parse(result), group: d.value.group, rule: d.value.rule, part: d.value.part}
                }, "*")
            })
        },
        checkNumberOfObjectsIntoRooms(d){
            let roomIds = new this.myModule.StringList()
            d.value.roomIds.map(l => {
                roomIds.push_back(l)
            })
            let entityIds = new this.myModule.StringList()
            d.value.entityIds.map(l => {
                entityIds.push_back(l)
            })
            let res = this.myModule.checkNumberOfObjectsIntoRooms(roomIds, entityIds, d.value.controlNumber)
            window.parent.postMessage({
                action: 'saveCollisionReports',
                value: {...JSON.parse(res), group: d.value.group, rule: d.value.rule, part: d.value.part}
            }, "*")
        },
        onSetColorForObjects(d){
            let list = new this.myModule.StringList()
            d.value.list.map(l => {
                list.push_back(l)
            })
            this.myModule.setColorForObjects(list, d.value.color)
        },
        onSetColorsForObjects(d){
            this.myModule.setColorsForObjects(JSON.stringify(d.value))
        },
        onUnsetColorsForObjects(){
            this.myModule.unsetColorsForObjects()
        },
        onObjectsHighlight(d){
            let list = new this.myModule.StringList()
            d.value.list.map(l => {
                list.push_back(l)
            })
            this.myModule.setObjectsHighlight(list, d.value.active)
        },
        onShowObjectsOnly(d){
            if(d.value.active){
                // this.myModule.disableCollisionsOnlyMode()
                let list = new this.myModule.StringList()
                d.value.list.map(l => {
                    list.push_back(l)
                })
                this.myModule.showObjectsOnly(list)
            }
            else{
                this.myModule.disableObjectsOnlyMode()
            }
        },
        onCreateSectionBoxForObjects(d){
            if(d.value.active){
                let list = new this.myModule.StringList()
                d.value.list.map(l => {
                    list.push_back(l)
                })
                this.myModule.createSectionBoxForObjects(list)
            }
            else{
                this.myModule.removeSectionBox()
            }
        },
        onCollisionsActive(d){
            let list = new this.myModule.StringList()
            d.value.list.map(l => {
                list.push_back(l)
            })
            this.myModule.setCollisionsActive(list, d.value.active)

            window.parent.postMessage({
                action: 'onRender',
                value: true
            }, "*")
        },
        onShowCollisionsOnly(d){
            if(d.value.active){
                this.myModule.disableObjectsOnlyMode()
                let list = new this.myModule.StringList()
                d.value.list.map(l => {
                    list.push_back(l)
                })
                this.myModule.showCollisionsOnly(list)
            }
            else{
                this.myModule.disableCollisionsOnlyMode()
            }
        },
        onCreateSectionBoxForCollisions(d){
            if(d.value.active){
                let list = new this.myModule.StringList()
                d.value.list.map(l => {
                    list.push_back(l)
                })
                this.myModule.createSectionBoxForCollisions(list)
            }
            else{
                this.myModule.removeSectionBox()
            }
        },
        onRemoveSectionBox(){
            this.myModule.removeSectionBox()
        },
        onLoadApplyFilters(v){
            window.parent.postMessage({
                action: 'onLoadApplyFilters',
                value: v
            }, "*")
        },
        onLoadModel(v){
            window.parent.postMessage({
                action: 'onLoadModel',
                value: v
            }, "*")
        },
        onReadyModel(v){
            window.parent.postMessage({
                action: 'onReadyModel',
                value: v
            }, "*")
            this.getRoomsColors()
        },
        onLoadProjectsInfo(data){
        console.log('onLoadProjectsInfo data', data)
        console.log('onLoadProjectsInfo this.modelId', this.modelId)
        console.log('onLoadProjectsInfo this.modelName', this.modelName)
            let list = this.modelId.map(id => {
                return {
                    [this.modelName[id].name]: {
                        project_id: data.projects[id] + ''
                    }
                }
            })
            console.log('onLoadProjectsInfo list', list)
            console.log('onLoadProjectsInfo JSON.stringify(list)', JSON.stringify(list))
            new Promise(resolve => {
                resolve(this.myModule.loadProjectsInfo(JSON.stringify(list)))
            })
            .then(() => {
                window.parent.postMessage({
                    action: 'onLoadProjectsInfo'
                }, "*")
            })
        },
        getRoomsColors(){
            new Promise(resolve => {
                resolve(this.myModule.getRoomsColors())
            })
            .then(res => {
                let colors = JSON.parse(res)
                window.parent.postMessage({
                    action: 'getRoomsColors',
                    value: colors.rooms
                }, "*")
            })

        },
        onGetHiddenObjects(){
            let ar = []
            let objs = this.myModule.hiddenObjects()
            for(let i = 0, len = objs.size(); i < len; i++){
                ar = [...ar, objs.get(i)]
            }
            window.parent.postMessage({
                action: 'onGetHiddenObjects',
                value: ar
            }, "*")
        },
        onDelta(d){
            this.myModule.clear()
            if(!d.value){
                let model = this.modelName[this.modelId[0]]
                this.myModule.loadModel(model.name, model.path, model.pathJson)
            }
            else{
                if(!this.delta.find(f => d.id)){
                    this.delta = [d.id]
                    this.token = d.token
                    this.$store.dispatch('getFile', {
                        id: d.id + '/obj.zip',
                        token: d.token,
                        typeBlob: 'blob'
                    })
                    .then(res => {
                        return Promise.resolve(res.file)
                    })
                    .then(res => JSZip.loadAsync(res))
                    .then(zip => {
                        let filesList = Object.keys(zip.files)
                        return Promise.all(this.aqq(filesList, zip))
                    })
                    .then(res => {
                        let files = {
                            files: []
                        }
                        res.map(r => {
                            if(r.type == 'file'){
                                files.files.push(r)
                            }
                            else{
                                files[r.type] = r
                            }
                        })

                        return Promise.resolve(files)
                    })
                    .then(res => {
                        return new Promise((resolve) => {
                            if(res.mtl){
                                this.createFile(res.mtl)
                            }
                            this.createFile(res.obj)
                            resolve(res)
                        })
                    })
                    .then(res => {
                        return new Promise((resolve) => {
                            let deltaJson = this.deltaJson
                            this.getDeltaJson()
                            .then(res => {
                                deltaJson = res
                                return this.getInformJson({id: d.id, type: d.prop.type})
                            })
                            .then(pathJson => {
                                this.deltaName[d.id] = {
                                    name: res.obj.name,
                                    path: res.obj.path,
                                    pathJson: pathJson
                                }
                                let model = this.modelName[this.modelId[0]]
                                this.myModule.loadDelta(
                                    model.name,
                                    model.path,
                                    model.pathJson,
                                    res.obj.name,
                                    res.obj.path,
                                    pathJson,
                                    deltaJson
                                )
                                resolve(true)
                            })
                        })
                    })
                }
                else{
                    let delta = this.deltaName[d.id]
                    let model = this.modelName[this.modelId[0]]
                    this.myModule.loadDelta(
                        model.name,
                        model.path,
                        model.pathJson,
                        delta.name,
                        delta.path,
                        delta.pathJson,
                        this.deltaJson
                    )
                }
            }
        },
        getDeltaJson(){
            return new Promise(resolve => {
                let delta = this.modelId[0]
                if(!this.deltaJson){
                    this.$store.dispatch('getFile', {
                        id: delta + '/delta.json',
                        token: this.token,
                        typeBlob: 'blob'
                    })
                    .then(res => {
                        return res.file.arrayBuffer()
                    })
                    .then(r => {
                        return this.createFile({
                            data: new Uint8Array(r),
                            name: delta + '_delta.json',
                            path: '/'
                        })
                    })
                    .then(r => {
                        this.deltaJson = '/' + delta + '_delta.json'
                        resolve('/' + delta + '_delta.json')
                    })
                }
                else{
                    resolve('/' + delta + '_delta.json')
                }
            })
        },
        getInformJson(params = {id: false, type: ''}){
            return new Promise(resolve => {
                let no_filter = params.id || this.modelId.find(f => !this.filterList.some(s => f == s))

                if(no_filter){
                    new Promise(resolve => {
                        this.$store.dispatch('getFile', {
                            id: no_filter + '/info',
                            token: this.token
                        })
                        .then(res => {
                            if(res.file.tags['mini.json']){
                                return this.$store.dispatch('getFile', {
                                    id: no_filter + '/mini.json',
                                    token: this.token,
                                    typeBlob: 'blob'
                                })
                                .then(res => {
                                    resolve(res.file.arrayBuffer())
                                })
                            }
                            else if(res.file.tags['json.zip']){
                                return this.$store.dispatch('getFile', {
                                    id: no_filter + '/json.zip',
                                    token: this.token,
                                    typeBlob: 'blob'
                                })
                                .then(res => {
                                    return Promise.resolve(res.file)
                                })
                                .then(res => JSZip.loadAsync(res))
                                .then(zip => {
                                    let filesList = Object.keys(zip.files)
                                    resolve(zip.file(filesList[0]).async("uint8array"))
                                })
                            }
                            else{
                                return this.$store.dispatch('getFile', {
                                    id: no_filter + '/json',
                                    token: this.token,
                                    typeBlob: 'blob'
                                })
                                .then(res => {
                                    resolve(res.file.arrayBuffer())
                                })
                            }
                        })
                    })
                    .then(r => {
                        return this.createFile({
                            data: new Uint8Array(r),
                            name: no_filter + '_filters.json',
                            path: '/'
                        })
                    })
                    .then(r => {
                        this.filters = true
                        this.filterList = [...this.filterList, no_filter]
                        resolve('/' + no_filter + '_filters.json')
                    })
                }
                else{
                    resolve('/' + no_filter + '_filters.json')
                }
            })
        },
        disableFilters(){
            this.myModule.disableFilters()
        },
        loadBackground(id, sts){
            console.log('loadBackground run')
            return new Promise((resolve, reject) => {
                const getBackground = () => {
                    this.$store.dispatch('getBackground', {
                        token: this.token,
                        url: id
                    })
                    .then(res => {
                        console.log('getBackground', res)
                        if(res.status == -1){
                                console.log('result', res.id)
                            resolve(id)
                        }
                        else if(res.status == -2){
                            console.log('get Background error: ', res.message)
                            reject({message: res.message})
                        }
                        else{
                            setTimeout(getBackground, 5000)
                        }
                    })
                    .catch(er => {
                        console.log('get Background error: ', er)
                        reject({message: er})
                    })
                }
                if(sts == -1){
                    resolve(id)
                }
                else if(sts == -2){
                    reject({message: 'get background ERROR' })
                }
                else{
                    getBackground()
                }
            })
        },
        getBackground(data){
            let task_id = ''
            let project = {}
            let passport_data_ru = {}

            return new Promise((resolve, reject) => {
                this.$store.dispatch('getProjectPSS', {
                    token: this.token,
                    job_api: this.job_api,
                    unitbim_file_id: this.modelId[0]
                })
                .then(res => {
                    console.log('projectPss res ok', res)
                    project = res
                    let key = Object.keys(project.result.object.passport_data_ru)[0]
                    passport_data_ru = project.result.object.passport_data_ru[key]

                        console.log('getBackgroundId', data)
                    let siz = data.geo.siz
                        ? data.geo.siz > 25000
                            ? 25
                            : data.geo.siz / 1000
                        : 0.5
                    return this.$store.dispatch('getBackgroundId', {
                        token: this.token,
                        coord: {
                            lat: data.geo.avg_lat,
                            lon: data.geo.avg_lon,
                            siz: siz
                        }
                    })
                })
                .then(res => {
                    return this.loadBackground(res.id, res.status)
                })
                .then(res => {
                    return this.$store.dispatch('getBackground', {
                        token: this.token,
                        url: res + '/output',
                        typeBlob: 'blob'
                    })
                })
                .then(res => JSZip.loadAsync(res))
                .then(zip => {
                    let filesList = Object.keys(zip.files)
                    return Promise.all(this.aqq(filesList, zip))
                })
                .then(res => {
                    let files = {
                        files: []
                    }
                    res.map(r => {
                        if(r.type == 'file'){
                            files.files.push(r)
                        }
                        else{
                            files[r.type] = r
                        }
                    })

                    return Promise.resolve(files)
                })
                .then(res => {
                    return new Promise((resolve) => {
                        if(res.files.length){
                            res.files.map(file => {
                                this.createFile(file)
                            })
                        }
                        if(res.mtl){
                            this.createFile(res.mtl)
                        }
                        this.createFile(res.obj)
                        resolve(res)
                    })
                })
                .then(res => {
                    console.log('file name', res)
                    const bgTrsf = {
                        Transformation: {
                            translation: JSON.parse(passport_data_ru.translation),
                            rotation: JSON.parse(passport_data_ru.rotation),
                            scale: JSON.parse(passport_data_ru.scale)
                        }
                    }
                        console.log('Transformation bgTrsf', bgTrsf)
                    this.myModule.loadBackground(res.obj.name, res.obj.path, JSON.stringify(bgTrsf))
                })
                .catch(er => {
                    console.log('catch er', er)
                })
            })
        },
        loadModel(v){
            this.modelJsonType = v.prop.json
            this.modelType = v.prop.type
            this.modelId = [...this.modelId, v.id]
            this.job_api = v.job_api,
            this.token = v.token
            this.$store.dispatch('getFile', {
                id: v.id + '/obj.zip',
                token: v.token,
                typeBlob: 'blob'
            })
            .then(res => {
                return Promise.resolve(res.file)
            })
            .then(res => JSZip.loadAsync(res))
            .then(zip => {
                let filesList = Object.keys(zip.files)
                return Promise.all(this.aqq(filesList, zip))
            })
            .then(res => {
                let files = {
                    files: []
                }
                res.map(r => {
                    if(r.type == 'file'){
                        files.files.push(r)
                    }
                    else{
                        files[r.type] = r
                    }
                })

                return Promise.resolve(files)
            })
            .then(res => {
                return new Promise((resolve) => {
                    if(res.files.length){
                        res.files.map(file => {
                            this.createFile(file)
                        })
                    }
                    if(res.mtl){
                        this.createFile(res.mtl)
                    }
                    this.createFile(res.obj)
                    resolve(res)
                })
            })
            .then(res => {
                window.parent.postMessage({
                    action: 'onStartLoadFilters',
                    value: true
                }, "*")
                return new Promise((resolve) => {
                    this.getInformJson({id: v.id, type: v.prop.type})
                    .then(pathJson => {
                        this.modelName[v.id] = {
                            name: res.obj.name,
                            path: res.obj.path,
                            pathJson: pathJson
                        }
                        this.myModule.loadModel(res.obj.name, res.obj.path, pathJson)
                        resolve(true)
                    })
                })
            })
            .then(res => {
                return new Promise((resolve) => {
                    this.disableFilters()
                    resolve(true)
                })
            })
            .then(res => {
                this.onReadyModel(true)
            })
        },
        aqq(filesList, zip){
            let list = []
            filesList.map(i => {
                if(i.split('.').pop() === 'obj'){
                    list.push(Promise.resolve(zip.file(i).async("uint8array"))
                        .then(r => {
                            return Promise.resolve({
                                type: 'obj',
                                path: '/',
                                name: i,
                                mainname: i.substring(0, i.length - 4),
                                basename: i.substring(0, i.length - 4),
                                data: r
                            })
                        }))
                }
                else if(i.split('.').pop() === 'mtl'){
                    list.push(Promise.resolve(zip.file(i).async("uint8array"))
                        .then(r => {
                            return Promise.resolve({
                                type: 'mtl',
                                name: i,
                                path: '/',
                                mainname: i.substring(0, i.length - 4),
                                basename: i.substring(0, i.length - 4),
                                data: r
                            })
                        }))
                }
                else{
                    list.push(Promise.resolve(zip.file(i).async("uint8array"))
                        .then(r => {
                            let path = i.split('/')
                            let mainname = i.substring(0, i.indexOf('_'))
                            return Promise.resolve({
                                type: 'file',
                                path: '/' + path[0],
                                name: path[1],
                                mainname: mainname,
                                basename: name.substring(0, name.lastIndexOf('.')),
                                data: r
                            })
                        }))
                }
            })

            return list
        },
        createFile(file){
            let aDataArray = file.data//new Uint8Array(file.data)
            const aDataBuffer = this.myModule._malloc(aDataArray.length)
            this.myModule.HEAPU8.set(aDataArray, aDataBuffer)
            if(!file.path || !(file.path == '/')){
                this.myModule['FS'].mkdir(file.path)
            }
            this.myModule['FS_createDataFile'](file.path, file.name, aDataArray, true, true)
            this.myModule._free(aDataBuffer)
        },
        receiveMessage(e){
            if(Object.prototype.hasOwnProperty.call(e.data, 'action')){
                switch(e.data.action){
                    case 'loadModel': this.loadModel(e.data)
                        break
                    case 'displayAxes': this.displayAxes(e.data)
                        break
                    case 'displayGrid': this.displayGrid(e.data)
                        break
                    case 'setSelectionMode': this.setSelectionMode(e.data)
                        break
                    case 'selectObjectById': this.selectObjectById(e.data)
                        break
                    case 'clearSelection': this.clearSelection(e.data)
                        break
                    case 'onLoadCollisions': this.loadCollisions()
                        break
                    case 'setCollisions': this.setCollisions(e.data)
                        break
                    case 'setWalkView': this.setWalkView(e.data)
                        break
                    case 'setView': this.setView(e.data)
                        break
                    case 'onFilter': this.onFilter(e.data)
                        break
                    case 'onSection': this.onSection(e.data)
                        break
                    case 'onHideObject': this.onHideObject(e.data.value)
                        break
                    case 'onShowObject': this.onShowObject(e.data.value)
                        break
                    case 'onGetHiddenObjects': this.onGetHiddenObjects(e.data.value)
                        break
                    case 'onRoomVisibility': this.onRoomVisibility(e.data)
                        break
                    case 'onRoomVisibilityItem': this.onRoomVisibilityItem(e.data)
                        break
                    case 'onComputeCollisions': this.onComputeCollisions(e.data)
                        break
                    case 'onComputeRepeat': this.onComputeRepeat(e.data)
                        break
                    case 'onComputeSpace': this.onComputeSpace(e.data)
                        break
                    case 'onComputeDistance': this.onComputeDistance(e.data)
                        break
                    case 'checkNumberOfObjectsIntoRooms': this.checkNumberOfObjectsIntoRooms(e.data)
                        break
                    case 'onCollisionsActive': this.onCollisionsActive(e.data)
                        break
                    case 'onShowCollisionsOnly': this.onShowCollisionsOnly(e.data)
                        break
                    case 'onCreateSectionBoxForCollisions': this.onCreateSectionBoxForCollisions(e.data)
                        break

                    case 'onSetColorForObjects': this.onSetColorForObjects(e.data)
                        break
                    case 'onSetColorsForObjects': this.onSetColorsForObjects(e.data)
                        break
                    case 'onUnsetColorsForObjects': this.onUnsetColorsForObjects(e.data)
                        break
                    case 'onObjectsHighlight': this.onObjectsHighlight(e.data)
                        break
                    case 'onShowObjectsOnly': this.onShowObjectsOnly(e.data)
                        break
                    case 'onCreateSectionBoxForObjects': this.onCreateSectionBoxForObjects(e.data)
                        break

                    case 'onRemoveSectionBox': this.onRemoveSectionBox()
                        break
                    case 'onDelta': this.onDelta(e.data)
                        break
                    case 'onLoadProjectsInfo': this.onLoadProjectsInfo(e.data)
                        break

                    case 'onBackground': this.getBackground(e.data)
                    default: ;
                }
            }
        },
        initEngine(){
            if(this.myModule === null){
                new Module({
                    canvas: this.$refs['canvas']
                }).then(myModule => {
                    this.myModule = myModule

                    this.myModule.setGetCanvasWidthCallback(
                        () => this.getCanvasWidth()
                    )
                    this.myModule.setGetCanvasHeightCallback(
                        () => this.getCanvasHeight()
                    )

                    this.myModule.showProxyObjects(true)
                    let VK_LeftButton = 1 << 13
                    let VK_CTRL = 1 <<  9
                    let MouseGesture_SelectRectangle = 1
                    this.myModule.setMouseGesture( VK_LeftButton | VK_CTRL,  MouseGesture_SelectRectangle)

                    this.myModule.setOnSelectedCallback(
                        id => this.onSelected(id)
                    )
                    this.myModule.setOnProgressCallback(
                        v => this.onLoadModel(v)
                    )
                    this.myModule.setOnFiltersApplyCallback(
                        v => this.onLoadApplyFilters(v)
                    )
                    this.myModule.setCollisionsCallback(
                        v => this.onCalculateCollisions(v)
                    )
                    // this.myModule.setCollisionMargin(-0.5)

                    this.myModule.setViewCubeSize(40 / this.getDevicePixelRatio())
                    this.myModule.setSelectionMode(0)
                    this.myModule.resize()

                    window.parent.postMessage({action: 'ready'}, "*")
                    // this.myModule.testViewObj()

                    window.addEventListener('resize', this.onWindowResize, false)
                })
            }
        },
        onWindowResize(){
            this.setCanvasSize()
            this.myModule.setGetCanvasWidthCallback(
                () => this.getCanvasWidth()
            )
            this.myModule.setGetCanvasHeightCallback(
                () => this.getCanvasHeight()
            )
            this.myModule.resize()
        }
    },
    mounted(){
        window.addEventListener("message", this.receiveMessage, false)
        // window.addEventListener('resize', this.onWindowResize, false)

        this.$nextTick(() => {
            setTimeout(this.setCanvasSize, 1000)
            setTimeout(this.initEngine, 2000)
        })
    },
    beforeDestroy(){
        window.removeEventListener("message", this.receiveMessage, false)
        window.removeEventListener('resize', this.onWindowResize, false)
    }
}
</script>

<style lang="less">
#canvas {
    width: 100%;
    height: 100%;
    display: block;
}
</style>
